import Head from 'next/head';
import { useReducer } from 'react';
import styled, { css } from 'styled-components';

import { Drawer } from '@/components/organisms/layout/moblle/Drawer';
import { Header } from '@/components/organisms/layout/moblle/Header';
import { SideMenu } from '@/components/organisms/layout/SideMenu';
import { useLayout } from '@/hooks/useLayout';

import { TabMenu } from '../organisms/layout/moblle/TabMenu';

type Props = {
  children: React.ReactNode;
  title?: string;
  isLogin?: boolean;
  drawerExists?: boolean;
};
export const Layout = ({ children, title = 'Dashboard', isLogin = false, drawerExists = true }: Props) => {
  const { isSPView } = useLayout();
  const [isOpenDrawer, toggleDrawer] = useReducer((state) => {
    return !state;
  }, false);

  return (
    <div>
      <Head>
        <title>{title}</title>
        <meta charSet="utf-8" />
      </Head>
      <Container>
        {isSPView ? (
          <>
            {isLogin ? (
              <LowerContainer>
                <Main isLogin={isLogin}>{children}</Main>
              </LowerContainer>
            ) : (
              <>
                <Header toggleDrawer={toggleDrawer} />
                <LowerContainer>
                  {drawerExists ? <Drawer isOpen={isOpenDrawer} onClose={toggleDrawer} /> : <></>}
                  <Main isLogin={isLogin}>{children}</Main>
                </LowerContainer>
                <TabMenu />
              </>
            )}
          </>
        ) : (
          <>
            {isLogin ? (
              <LowerContainer>
                <Main isLogin={isLogin}>{children}</Main>
              </LowerContainer>
            ) : (
              <LowerContainer>
                <SideMenu />
                <Main isLogin={isLogin}>{children}</Main>
              </LowerContainer>
            )}
          </>
        )}
      </Container>
    </div>
  );
};

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100svh;
  font-size: ${({ theme }) => theme.fontSizes.base}px;
`;

const LowerContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow-y: hidden;
`;

const Main = styled.main<{ isLogin: boolean }>`
  background-color: ${({ theme }) => theme.colors.background};
  flex: 1;
  overflow-y: auto;
  overscroll-behavior-y: contain;
  display: flex;
  flex-direction: column;
  ${({ isLogin }) =>
    isLogin
      ? css`
          padding: 100px 0px 100px 0px;
          justify-content: center;
        `
      : ``}
`;
