import router from 'next/router';
import { MouseEvent, useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import MoreSvg from '/public/gymx/more.svg';
import useGym from '@/hooks/useGym';

type Props = {
  isSPView?: boolean;
};

type TOption = {
  label: string;
  value: string | null;
};

type OptionProps = {
  option: TOption;
  selected?: boolean;
  onClick: (v: TOption['value']) => void;
};

export const StoreSelector = ({ isSPView }: Props) => {
  const { selectedGym, gymList } = useGym();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleClickSelector = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      setIsOpen(!isOpen);
    },
    [isOpen],
  );

  const handleClickOption = useCallback((v: TOption['value']) => {
    router.push(`/${v}`);
  }, []);

  const closeOption = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  useEffect(() => {
    if (!isOpen) {
      document.body.removeEventListener('click', closeOption);
      return;
    }
    document.body.addEventListener('click', closeOption);
  }, [isOpen, closeOption]);

  return (
    <Container onClick={handleClickSelector}>
      <SelectorWrapper>
        <Text isSPView={isSPView}>{selectedGym?.name}</Text>
        {isOpen && (
          <SelectorOpen isSPView={isSPView}>
            {gymList.map((x, index) => (
              <Option
                key={index}
                option={{
                  label: x.name === undefined ? '' : x.name,
                  value: x.id === undefined ? '' : `${x.id}`,
                }}
                onClick={handleClickOption}
              />
            ))}
          </SelectorOpen>
        )}
      </SelectorWrapper>
      <IconBox>
        <MoreSvg />
      </IconBox>
    </Container>
  );
};

const Option = ({ option, selected = false, onClick }: OptionProps) => {
  return (
    <StyledOption selected={selected} onClick={() => onClick(option.value)}>
      <StyledOptionText>{option.label}</StyledOptionText>
    </StyledOption>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  width: 195px;
  height: 32px;
`;

const SelectorWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:hover {
    cursor: pointer;
  }
`;

const Text = styled.span<{ isSPView?: boolean }>`
  font-size: ${({ isSPView }) => (isSPView ? '14px' : 'inherit')};
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  line-height: 1.5;
`;

const IconBox = styled.div`
  padding: 0 10px;
  align-items: center;
  display: flex;
`;

const StyledOption = styled.div<{ selected: boolean }>`
  padding: 8px 16px;
  display: flex;
  border-bottom: ${({ theme }) => `${theme.colors.inputBorder}`} 1px solid;
  align-items: center;
  width: 100%;
  height: 35px;
  ${({ selected, theme }) =>
    selected &&
    css`
      background-color: ${theme.colors.selectedColor};
    `}
  :first-child {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
  ${({ selected }) =>
    !selected &&
    css`
      &:hover {
        background-color: #f4f4f4;
      }
    `}
`;

const StyledOptionText = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`;

const SelectorOpen = styled.div<{ isSPView?: boolean }>`
  position: absolute;
  top: ${({ isSPView }) => (isSPView ? '100%' : 'auto')};
  bottom: ${({ isSPView }) => (isSPView ? 'auto' : '0')};
  left: ${({ isSPView }) => (isSPView ? '0' : '100%')};
  max-width: ${({ isSPView }) => (isSPView ? '240px' : '400px')};
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white};
  border: ${({ theme }) => `${theme.colors.inputBorder}`} 1px solid;
  font-size: ${({ theme }) => `${theme.fontSizes.base}`}px;
  border-radius: 6px;
  color: ${({ theme }) => `${theme.colors.formLabel}`};
  max-height: 600px;
  overflow-y: auto;
  z-index: 10000;
`;
