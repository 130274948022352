import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import HamburgerSvg from '/public/gymx/hamburger.svg';
import { isLoggedInSelector } from '@/store/selector/adminSelector';
import { logSlice } from '@/store/slice/logSlice';
import { userSlice } from '@/store/slice/userSlice';

import { StoreSelector } from '../StoreSelector';

export const HEADER_HEIGHT = 57;

type Props = {
  toggleDrawer: () => void;
};
export const Header = ({ toggleDrawer }: Props) => {
  const isLoggedIn = useSelector(isLoggedInSelector);
  const router = useRouter();
  const dispatch = useDispatch();

  const handleClickHamburger = () => {
    toggleDrawer();
  };

  const handleTitleClick = () => {
    if (isLoggedIn) {
      router.push('/');
      dispatch(userSlice.actions.reset());
      dispatch(logSlice.actions.reset());
      return;
    }
  };

  return (
    <Container>
      <Flex>
        <Title onClick={handleTitleClick}>
          <TitleImage src="/gymx/title_logo.png" alt="title_logo" width={124} height={31} />
        </Title>
        <StoreSelector isSPView />
      </Flex>
      <ButtonArea>
        <IconBox onClick={handleClickHamburger}>
          <HamburgerSvg />
        </IconBox>
      </ButtonArea>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
  height: ${HEADER_HEIGHT}px;
  background-color: ${({ theme }) => theme.colors.primary};
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.white};
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonArea = styled(Flex)`
  margin-right: 8px;
`;

const Title = styled.div`
  width: 124px;
  margin-left: 8px;
`;

const TitleImage = styled.img`
  vertical-align: bottom;
`;

const IconBox = styled.div`
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 4px;
`;
