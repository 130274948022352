import { useEffect, useMemo, useState } from 'react';

import { SP_VIEW_MAX_WIDTH } from '@/constant';

export const useLayout = () => {
  const [width, setWidth] = useState<number>(0);
  const isSPView = useMemo(() => width <= SP_VIEW_MAX_WIDTH, [width]);

  useEffect(() => {
    if (typeof document !== 'undefined') {
      const element = document.body;
      const observer = new ResizeObserver((entries) => {
        setWidth(entries[0].contentRect.width);
      });
      if (element) {
        //要素のリサイズを監視
        observer.observe(element);
      }
      //クリーンアップ関数で監視を解く
      return (): void => {
        observer.disconnect();
      };
    }
  }, []);

  return { isSPView };
};
